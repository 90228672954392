<template>
  <div class="container">
    <!-- 头部 -->
    <Header :tab="5"/>
    <!-- 身体 -->
    <div class="main">
      <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <img v-if="obj" :src="obj.info_image" class="banner_r_img_s" alt />
        <!-- <div class="banner_l">
         <div class="banner_l_title">Case Show</div>
          <div class="banner_l_title">{{ obj.title }}</div>
          <div class="banner_l_border"></div>
          <div class="banner_l_txt">
            <span v-for="(item, index) in obj.tags" :key="index">
              {{ item }}
              <span v-if="index != obj.tags.length - 1">/</span>
            </span>
          </div>
        </div>
        <div class="banner_r">
          <img src="../../assets/imgs/jianshe.png" class="banner_r_img" alt />
        </div> -->
      </div>

      <!-- 主体 -->
      <div class="main_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <!-- 头部 -->
        <div class="main_box_top wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div class="main_box_top_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div v-if="obj" v-html="obj.content"></div>
          </div>
          <div class="main_box_top_r wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_top_r_img_box" v-if="obj">
              <img :src="obj.company_logo" class="main_box_top_r_img" alt />
            </div>
            <div class="main_box_top_r_title" v-if="obj">{{ obj.company_name }}</div>
            <div class="main_box_top_r_txt" v-if="obj">{{ obj.company_info }}</div>
            <div class="main_box_top_r_center" v-if="obj">
              <span v-for="(item, index) in obj.company_tags" :key="index">{{ item }}</span>
            </div>
            <div class="main_box_top_r_btn" v-if="obj">
              <div class="main_box_top_r_item" @click="prevs(obj.prev)" v-if="obj.prev">
                <!-- <img src="../../assets/imgs/312zuo.png" class="main_box_top_r_item_img" alt /> -->
                <img src="../../assets/imgs/zuo11.png" class="main_box_top_r_item_img" alt />
              </div>
              <div class="main_box_top_r_item" @click="nexts(obj.next)" v-if="obj.next">
                <!-- <img src="../../assets/imgs/312you.png" class="main_box_top_r_item_img" alt /> -->
                <img src="../../assets/imgs/you12.png" class="main_box_top_r_item_img" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
  </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import { project } from "@/api/axios/axios.api";

import { WOW } from "wowjs";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      height_top: storage.height_top,
      id: "",
      obj: null
    };
  },
  methods: {
    // 上一页
    prevs(id) {
      if (id) {
        this.id = id;
        this.getDetails();
      }
    },
    // 下一页
    nexts(id) {
      if (id) {
        this.id = id;
        this.getDetails();
      }
    },
    // 获取数据
    async getDetails() {
      // 案例详情
      let res = await project(this.id);
      this.obj = res.data;
      this.obj.content = res.data.content.replace(
        /<img/gi,
        "<img style=max-width:100%;height:auto"
      );
      this.obj.content = res.data.content.replace(
        /<div/gi,
        "<div style=max-width:100%;height:auto"
      );
    }
  },
  created() { },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetails();
    this.$nextTick(() => {

      let wow = new WOW({
        live: true
      });
      wow.init();

    });
  },
  watch: {}
};
</script>
  
<style lang="less" scoped>
@media screen and (min-width : 1400px) {
  .banner {
    color: #fff;
    // height: 609px;
    background: #002437;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;

    .banner_l {
      color: #fff;
      width: 50%;

      .banner_l_title {
        line-height: 34px;
        font-size: 52px;
        margin-bottom: 40px;
        font-family: iFontszhounianti;
      }

      .banner_l_title:nth-child(2) {
        margin: 0;
      }

      .banner_l_border {
        width: 76px;
        height: 3px;
        background: #ffffff;
        margin: 50px 0;
      }

      .banner_l_txt {
        font-size: 16px;
      }
    }

    .banner_r {
      width: 50%;

      .banner_r_img {
        width: 100%;
        transition: all 0.5s;
      }

      .banner_r_img:hover {
        transform: scale(1.2);
        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        transition: all 0.5s;
      }
    }

    .banner_r_img_s {
      width: 100%;
    }
  }

  // 身体
  .main_box {
    padding-left: 10%;

    // 头部
    .main_box_top {
      box-sizing: border-box;
      display: flex;

      .main_box_top_l {
        font-size: 14px;
        width: 60%;
        margin: 2%;
        box-sizing: border-box;
        background-color: #fff;
      }

      .main_box_top_r {
        padding: 0 1%;
        padding-right: 10%;
        background-color: #fff;
        flex: 1;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .main_box_top_r_img_box {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          margin-top: 80px;

          .main_box_top_r_img {
            width: 80px;
            height: 80px;
          }
        }

        .main_box_top_r_title {
          font-size: 34px;
          color: #333333;
          margin: 50px 0;
          text-align: center;
        }

        .main_box_top_r_txt {
          line-height: 34px;
        }

        .main_box_top_r_center {
          height: 85px;
          line-height: 85px;
          margin-top: 28px;
          color: #999999;
          border-top: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;

          span {
            margin-right: 10px;
          }
        }

        .main_box_top_r_btn {
          display: flex;
          margin: 40px 0;

          .main_box_top_r_item {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            transition: all 0.5s;
            background-color: #666666;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5%;
            cursor: pointer;

            .main_box_top_r_item_img {
              transition: all 0.5s;
              // width: 30px;
              width: 55px;
            }
          }

          .main_box_top_r_item:hover {
            transition: all 0.5s;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          }

          .main_box_top_r_item:hover .main_box_top_r_item_img {
            transition: all 0.5s;
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
@media screen and (max-width : 1399px) {
  .banner {
    min-width: 1300px;
    color: #fff;
    // height: 609px;
    background: #002437;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;

    .banner_l {
      color: #fff;
      width: 50%;

      .banner_l_title {
        line-height: 34px;
        font-size: 52px;
        margin-bottom: 40px;
        font-family: iFontszhounianti;
      }

      .banner_l_title:nth-child(2) {
        margin: 0;
      }

      .banner_l_border {
        width: 76px;
        height: 3px;
        background: #ffffff;
        margin: 50px 0;
      }

      .banner_l_txt {
        font-size: 16px;
      }
    }

    .banner_r {
      width: 50%;

      .banner_r_img {
        width: 100%;
        transition: all 0.5s;
      }

      .banner_r_img:hover {
        transform: scale(1.2);
        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        transition: all 0.5s;
      }
    }

    .banner_r_img_s {
      width: 100%;
    }
  }

  // 身体
  .main_box {
    // padding-left: 20px;
    min-width: 1300px;
    // 头部
    .main_box_top {
      box-sizing: border-box;
      display: flex;

      .main_box_top_l {
        font-size: 14px;
        width: 60%;
        margin: 2%;
        box-sizing: border-box;
        background-color: #fff;
      }

      .main_box_top_r {
        padding: 0 1%;
        padding-right: 20px;
        background-color: #fff;
        flex: 1;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .main_box_top_r_img_box {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          margin-top: 80px;

          .main_box_top_r_img {
            width: 80px;
            height: 80px;
          }
        }

        .main_box_top_r_title {
          font-size: 34px;
          color: #333333;
          margin: 50px 0;
          text-align: center;
        }

        .main_box_top_r_txt {
          line-height: 34px;
        }

        .main_box_top_r_center {
          height: 85px;
          line-height: 85px;
          margin-top: 28px;
          color: #999999;
          border-top: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;

          span {
            margin-right: 10px;
          }
        }

        .main_box_top_r_btn {
          display: flex;
          margin: 40px 0;

          .main_box_top_r_item {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            transition: all 0.5s;
            background-color: #666666;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5%;
            cursor: pointer;

            .main_box_top_r_item_img {
              transition: all 0.5s;
              // width: 30px;
              width: 55px;
            }
          }

          .main_box_top_r_item:hover {
            transition: all 0.5s;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          }

          .main_box_top_r_item:hover .main_box_top_r_item_img {
            transition: all 0.5s;
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
</style>
  